@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
h3 h2 {
  color:"blue"
}
pre {
  border-radius: 4px;
  background-color: #191c27;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px
}

code[class*="language-"],
pre[class*="language-"] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.boolean {}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string{
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #EDEDED;
}

.token.entity {
  color: #FFFFB6;
  cursor: help;
}

.token.url {
  color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
  color: #87C38A;
}

.token.atrule,
.token.attr-value {
  color: #F9EE98;
}

.token.function {
  color: rgb(121, 182, 242)
}

.token.regex {
  color: #E9C062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: aqua;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  height: 100vh;
  width: 100%;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  /* background: #444; */
  padding-top: env(safe-area-inset-top);
}

.MuiGrid-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 1rem;
  color: #fff;
  margin: 1rem 0;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s;
}

.add-note-table {
  width: 100%;
  margin: 10px;
  border-collapse: collapse;
}

.add-note-table td {
  padding: 5px;
  word-break: break-all;
  padding: 10px 0;
}
.add-note-table tr {
  border-bottom: 1pt solid #e0e0e0;
}

#add-note-table-note {
  width: 35%;
}

.containerGiorniCiapiV28 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: minmax(50px, auto);
  grid-gap: 10px;
  padding: 10px;
}

.css-j5h6pi-MuiPopper-root
{
  z-index: 2002 !important;
}


